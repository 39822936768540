import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import Column, { AutoColumn, ColumnCenter } from 'components/Column'
import { TYPE } from 'theme'
import { AutoRow, RowBetween, RowFixed } from 'components/Row'
import LineChart from 'components/LineChart/alt'
import { useProtocolData, useProtocolChartData, useProtocolTransactions } from 'state/protocol/hooks'
import { formatAmount, formatDollarAmount } from 'utils/numbers'
import { PageWrapper, ColorBackground } from 'pages/styled'
import { unixToDate } from 'utils/date'
import BarChart from 'components/BarChart/alt'
import TransactionsTable from '../../components/TransactionsTable'
import { WorkSans, BaiJamjureeer } from 'components/shared'
import { useActiveNetworkVersion } from 'state/application/hooks'
import { useTransformedVolumeData } from 'hooks/chart'
import { SmallOptionButton } from 'components/Button'
import { TransactionType, VolumeWindow } from 'types'
import { IncubationTokenRevenue, IncubationTokenSymbols, notEmpty, PoolLabels } from 'utils'
import { StakedWomData } from 'state/protocol/reducer'
import { WomSupplyData } from 'data/protocol/overview'
import { ONE_DAY_SECONDS } from 'constants/intervals'
import { useAllAssetData } from 'state/assets/hooks'
import AssetTable from 'components/assets/AssetTable'
import { IncubationTokens } from 'utils'
import { ProjectLogo } from 'components/CurrencyLogo'
import { fetchCurrentTokenPrice } from 'hooks/useTokenPrice'
import { MouseoverTooltip } from 'components/Tooltip'
import useBreakpoints from 'hooks/useBreakpoints'
import { useCirculatingSupply, useTotalLockedWom } from 'hooks/useWombatApi'
import { TransactionSort } from 'components/TransactionsSort'
import { SupportedNetwork } from 'constants/networks'
import ShareIcon from '../../wombat-assets/icons/share.svg'
import PairTable, { PairData } from '../../components/assets/PairTable'

const ChartAndInfoSection = styled(RowBetween)`
  position: relative;
  width: 100%;
  @media only screen and (max-width: 1400px) {
    flex-direction: column;
  }
`

interface InfoWrapperProps {
  height?: string
}

const InfoWrapper = styled.div<InfoWrapperProps>`
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: #faf7ef;
  gap: 0.6em;
  font-family: 'Work Sans', sans-serif;
  font-style: normal;
  border-radius: 13px;
  padding: 23px;
  height: ${(props) => props.height || '50%'};
  justify-content: space-between;
  border: #ef893c solid 2px;
`

export const WhiteLineChart = styled(LineChart)`
  background-color: #faf7ef;
`

export const WhiteBarChart = styled(BarChart)`
  background-color: #faf7ef;
`

const ChartWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #faf7ef;
  display: flex;
  padding: 0;
  opacity: 0.8;
  border-radius: 13px;
  height: 100%;
  padding: 7px 10px;
  padding-bottom: 20px;
  border: #ef893c solid 2px;
`

const Vertical = styled.div`
  height: 100%;
  border: 1px solid ${({ theme }) => theme.wombatBrown1};
  margin: 5px auto;
`

const ColumGap = styled.div`
  width: 5%;
  border: 0px solid black;
  margin-bottom: 20px;
  @media screen and (max-width: 1300px) {
    margin-bottom: 14px;
  }
  @media screen and (max-width: 900px) {
    margin-bottom: 8px;
  }
  @media screen and (max-width: 720px) {
    margin-bottom: 20px;
  }
`

const StakePercentageChangesContainer = styled.div`
  margin-top: 0.5em;
  display: flex;
  flex: 1;
`
const Link = styled.a`
  text-decoration: none;
  color: #695b51;
`

const StyledShareIcon = styled.img<{ size: string }>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  display: inline-block;
  vertical-align: -2px;
  margin-left: 4px;
`

function getVolumeWindowDescription(window: VolumeWindow) {
  switch (window) {
    case VolumeWindow.daily:
      return '24H'
    case VolumeWindow.monthly:
      return '1M'
    case VolumeWindow.weekly:
      return '7D'
  }
}

function getInitIncubationRevenue(): IncubationTokenRevenue<number | null> {
  return {
    [IncubationTokenSymbols.MGP]: 0,
    [IncubationTokenSymbols.QUO]: 0,
    [IncubationTokenSymbols.WMX]: 0,
    [IncubationTokenSymbols.SABLE]: 0,
  }
}

export default function Home() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { isMobileLg, isTabletLg } = useBreakpoints()

  const [activeNetwork] = useActiveNetworkVersion()

  const [protocolData] = useProtocolData()
  const [chartData] = useProtocolChartData()
  const [transactions] = useProtocolTransactions()
  const [volumeHover, setVolumeHover] = useState<number | undefined>()
  const [stakedWomData, setStakedWomData] = useState<StakedWomData | undefined>()
  const [womSupplyData, setWomSupplyData] = useState<WomSupplyData | undefined>()
  const [totalFeeCollected, setTotalFeeCollected] = useState<number | undefined>()
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>()
  const [leftLabel, setLeftLabel] = useState<string | undefined>()
  const [rightLabel, setRightLabel] = useState<string | undefined>()
  const [totalBribeRevenue, setTotalBribeRevene] = useState<number | undefined>()
  const [pairDatas, setPairDatas] = useState<PairData[]>([])

  const allAssets = useAllAssetData()
  const formattedAssets = useMemo(() => {
    return Object.values(allAssets)
      .map((t) => t.data)
      .filter(notEmpty)
  }, [allAssets])

  const filteredAsset = formattedAssets.filter((asset) => {
    return (
      !!asset && process.env.REACT_APP_ENVIRONMENT === 'production' ? asset.tvlUSD > 500 : true,
      asset.pool !== PoolLabels.BNB
    )
  })

  let totalLiquidityUSD = 0
  filteredAsset.forEach((asset) => {
    totalLiquidityUSD += asset.tvlUSD
  })

  // useEffect(() => {
  //   const _incubationRevenue = getInitIncubationRevenue()
  //   let totalRevenue = 0
  //   IncubationTokens.forEach(async (token) => {
  //     const revenue = (await fetchCurrentTokenPrice('bsc', token.address)) * token.quantity
  //     _incubationRevenue[token.symbol] = revenue
  //     totalRevenue += revenue
  //     setIncubationRevenue(_incubationRevenue)
  //     setTotalIncubationRevenue(totalRevenue)
  //   })
  // }, [])

  useEffect(() => {
    async function fetchPairDatas() {
      try {
        const [responseV2, responseV3] = await Promise.all([
          // fetch('https://api.wombat.exchange/api/v1/capy/v2/pairs'),
          // fetch('https://api.wombat.exchange/api/v1/capy/v3/pools'),
          fetch('https://api.wombat.exchange/api/v1/capy/v2/pairs?whitelistBypass=true'),
          fetch('https://api.wombat.exchange/api/v1/capy/v3/pools?whitelistBypass=true'),
        ])
        const resultV2: {
          pairs: PairData[]
        } = await responseV2.json()
        const resultV3: {
          pools: PairData[]
        } = await responseV3.json()
        const pairs = [...resultV2.pairs, ...resultV3.pools]
        setPairDatas(pairs)
      } catch (err) {
        console.error('Error happened fetching pairs', err)
        setPairDatas([])
      }
    }
    fetchPairDatas().then()
  }, [])

  useEffect(() => {
    setLiquidityHover(undefined)
    setVolumeHover(undefined)
  }, [activeNetwork])

  // if hover value undefined, reset to current day value
  useEffect(() => {
    if (volumeHover === undefined && protocolData) {
      setVolumeHover(protocolData.volumeUSD)
    }
  }, [protocolData, volumeHover])
  useEffect(() => {
    if (stakedWomData === undefined && protocolData) {
      const _stakedWomData = protocolData.stakedWomData[activeNetwork.id]
      setStakedWomData(_stakedWomData)
    }
  }, [activeNetwork.id, protocolData, stakedWomData])
  useEffect(() => {
    if (womSupplyData === undefined && protocolData) {
      setWomSupplyData(protocolData.womSupplyData)
    }
  }, [protocolData, womSupplyData])
  useEffect(() => {
    if (totalFeeCollected === undefined && protocolData) {
      setTotalFeeCollected(protocolData.totalFeeCollected)
    }
  }, [protocolData, totalFeeCollected])
  useEffect(() => {
    if (totalBribeRevenue === undefined && protocolData) {
      setTotalBribeRevene(protocolData.totalBribeRevenue)
    }
  }, [protocolData, totalBribeRevenue])
  const formattedTvlData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.tvlUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])
  useEffect(() => {
    if (liquidityHover === undefined && formattedTvlData.length) {
      if (totalLiquidityUSD > 0) {
        setLiquidityHover(totalLiquidityUSD)
      }
    }
  }, [protocolData, liquidityHover, formattedTvlData, totalLiquidityUSD])
  const formattedVolumeData = useMemo(() => {
    if (chartData) {
      return chartData.map((day) => {
        return {
          time: unixToDate(day.date),
          value: day.volumeUSD,
        }
      })
    } else {
      return []
    }
  }, [chartData])

  const weeklyVolumeData = useTransformedVolumeData(chartData, 'week')
  const monthlyVolumeData = useTransformedVolumeData(chartData, 'month')
  const [volumeWindow, setVolumeWindow] = useState(VolumeWindow.daily)
  let staked24h = 0,
    staked24hPC = 0,
    staked7d = 0,
    staked7dPC = 0,
    avgLockTime = 0,
    currentTotalSupply = 0
  if (stakedWomData) {
    staked24h = stakedWomData.stakedToday - stakedWomData.stakedYesterday || 0
    staked24hPC = (stakedWomData.stakedYesterday ? staked24h / stakedWomData.stakedYesterday : 0) * 100
    staked7d = stakedWomData.stakedToday - stakedWomData.stakedLastWeek || 0
    staked7dPC = (stakedWomData.stakedLastWeek ? staked7d / stakedWomData.stakedLastWeek : 0) * 100
    avgLockTime = stakedWomData.avgLockTimeInSec / ONE_DAY_SECONDS
    currentTotalSupply = stakedWomData.currentTotalSupply
  }

  const stakedToday = stakedWomData?.stakedToday ?? '0'
  const circulatingSupply = useCirculatingSupply() ?? 0
  const totalLockedWom = useTotalLockedWom() ?? 0
  const circulatingSupplyStakedPercentage =
    (Number(stakedToday) / (Number(circulatingSupply) + Number(totalLockedWom))) * 100

  const [txFilter, setTxFilter] = useState<TransactionType | undefined>(undefined)

  return (
    <PageWrapper>
      <AutoColumn gap="16px">
        <ChartAndInfoSection style={{ gap: '0.5em', alignItems: 'flex-start' }}>
          <Column style={{ width: '100%', height: '100%' }} gap="0.5em">
            {/* Locking */}
            {/* Temporarily remove Locking as there is no veWOM/veToken */}
            {/* Liquidity */}
            <ChartWrapper>
              <AutoColumn gap="4px" style={{ padding: '14px 14px 0px 14px' }}>
                <BaiJamjureeer>
                  <TYPE.mediumHeader fontSize="18px">LIQUIDITY</TYPE.mediumHeader>
                </BaiJamjureeer>
                <TYPE.largeHeader fontSize={isMobileLg ? '16px' : '18px'}>
                  <WorkSans>{formatDollarAmount(liquidityHover, 2, true)} </WorkSans>
                </TYPE.largeHeader>
                <TYPE.main fontSize="12px" height="14px">
                  {leftLabel ? <WorkSans>{leftLabel} (UTC)</WorkSans> : null}
                </TYPE.main>
              </AutoColumn>
              <WhiteLineChart
                data={formattedTvlData}
                value={liquidityHover}
                label={leftLabel}
                setValue={setLiquidityHover}
                setLabel={setLeftLabel}
              />
            </ChartWrapper>
          </Column>
          <Column style={{ width: '100%', height: '100%' }} gap="0.5em">
            {/* Revenue */}
            {/* Temporarily remove revenue as it is Wombat's */}
            {/* Volume */}
            <ChartWrapper>
              <RowBetween style={{ padding: '14px 14px 0px 14px' }}>
                <AutoColumn gap="4px">
                  <BaiJamjureeer>
                    <TYPE.mediumHeader fontSize="18px">
                      VOLUME [{getVolumeWindowDescription(volumeWindow)}]
                    </TYPE.mediumHeader>
                  </BaiJamjureeer>
                  <TYPE.largeHeader fontSize={isMobileLg ? '16px' : '18px'}>
                    <WorkSans> {formatDollarAmount(volumeHover, 2)}</WorkSans>
                  </TYPE.largeHeader>
                  <TYPE.main fontSize="12px" height="14px">
                    {rightLabel ? <WorkSans>{rightLabel} (UTC)</WorkSans> : null}
                  </TYPE.main>
                </AutoColumn>
                <RowFixed style={{ marginLeft: '-40px', marginTop: '8px' }}>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.daily}
                    onClick={() => setVolumeWindow(VolumeWindow.daily)}
                  >
                    D
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.weekly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.weekly)}
                  >
                    W
                  </SmallOptionButton>
                  <SmallOptionButton
                    active={volumeWindow === VolumeWindow.monthly}
                    style={{ marginLeft: '8px' }}
                    onClick={() => setVolumeWindow(VolumeWindow.monthly)}
                  >
                    M
                  </SmallOptionButton>
                </RowFixed>
              </RowBetween>
              <WhiteBarChart
                height={300}
                data={
                  volumeWindow === VolumeWindow.monthly
                    ? monthlyVolumeData
                    : volumeWindow === VolumeWindow.weekly
                    ? weeklyVolumeData
                    : formattedVolumeData
                }
                setValue={setVolumeHover}
                setLabel={setRightLabel}
                value={volumeHover}
                label={rightLabel}
                activeWindow={volumeWindow}
              />
            </ChartWrapper>
          </Column>
        </ChartAndInfoSection>
        <RowBetween>
          <TYPE.main fontSize={18} fontWeight={700} color={'black'}>
            <WorkSans>Top Tokens</WorkSans>
          </TYPE.main>
        </RowBetween>
        <AssetTable assetDatas={filteredAsset} maxItems={10} />
        <RowBetween>
          <TYPE.main fontSize={18} fontWeight={700} color={'black'}>
            <WorkSans>Top Pairs</WorkSans>
          </TYPE.main>
        </RowBetween>
        <PairTable pairDatas={pairDatas} maxItems={10} />
        <RowFixed>
          <TYPE.main fontSize={18} fontWeight={700} color={'black'} marginRight={20}>
            <WorkSans>Transactions</WorkSans>
          </TYPE.main>
          <TransactionSort txFilter={txFilter} setTxFilter={setTxFilter}></TransactionSort>
        </RowFixed>
        {transactions ? (
          <TransactionsTable
            transactions={transactions}
            maxItems={10}
            color={activeNetwork.primaryColor}
            txFilter={txFilter}
          />
        ) : null}
      </AutoColumn>
    </PageWrapper>
  )
}
