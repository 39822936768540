import { useCallback, useEffect, useState } from 'react'
import { AssetData } from './reducer'
import { useAddAssetKeys, useUpdateAssetData } from './hooks'

export function useFetchApiAssets() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<Error | null>(null)
  const [data, setData] = useState<AssetData[]>([])

  const fetchData = useCallback(async () => {
    try {
      setLoading(true)
      const [responseV2, responseV3] = await Promise.all([
        fetch('https://api.wombat.exchange/api/v1/capy/v2/tokens'),
        fetch('https://api.wombat.exchange/api/v1/capy/v3/tokens'),
      ])
      const resultV2: { assets: AssetData[] } = await responseV2.json()
      const resultV3: { assets: AssetData[] } = await responseV3.json()
      const assets = [...resultV2.assets, ...resultV3.assets]

      setData(assets)
      setError(null)
    } catch (err) {
      setError(err instanceof Error ? err : new Error('Failed to fetch API data'))
    } finally {
      setLoading(false)
    }
  }, [])

  return { loading, error, data, refetch: fetchData }
}

export default function Updater(): null {
  const updateAssetDatas = useUpdateAssetData()
  const addAssetKeys = useAddAssetKeys()
  const { loading, error, data, refetch } = useFetchApiAssets()

  useEffect(() => {
    if (data && !error && !loading) {
      const addresses = data.map((asset) => asset.address)
      addAssetKeys(addresses)
      updateAssetDatas(data)
    }
  }, [addAssetKeys, updateAssetDatas, data, error, loading])

  // Fetch API data on component mount
  useEffect(() => {
    refetch()
  }, [refetch])

  return null
}
